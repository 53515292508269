<template>
  <div>
    <div class="record_title padding-sm">
        <img class="record_back" src="img/backb.png" alt="" @click="closePage">
        <span>PK记录</span>
    </div>
    <div class="record_list padding-top-sm">
      
      <div class="pk_noData" v-if="pkList.length == 0">
        <img src="img/noData.png" alt="">
      </div>

      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="fetchData">
        <van-cell v-for="(item,index) in pkList" :key="index">

          <div class="record_item display_flex justify-content_flex-justify align-items_center padding-tb-sm margin-lr-sm" @click="goNext(item.id)">
            <div class="display_flex align-items_center">
                <img class="cha-img" :src="item.user_info.pic ? item.user_info.pic:'img/nickPic.png'" alt="" @click.stop="checkUser(item.user_info.id)">
                <div class="margin-left-sm">
                    <div class="display_flex align-items_center">
                        <div class="cha-name">{{item.user_info.nickname}}</div>
                        <div class="cha-level margin-left-xs" :style="{backgroundImage: 'url(' + 'img/level/level0/1.png' + ')'}"></div>
                    </div>
                    <div class="cha-id margin-top-sm">{{item.user_info.u_id}}</div>
                </div>
            </div>
            <div class="record_right display_flex flex-direction_column align-items_flex-end">
                <span>{{item.pk_begin_time}}</span>
                <!-- 胜利 -->
                <img v-if="item.pk_status == 1" class="margin-top-sm" src="img/status1.png" alt="">
                <!-- 提前结束 -->
                <img v-if="item.pk_status == 4" class="margin-top-sm" src="img/status2.png" alt="">
                <!-- 失败 -->
                <img v-if="item.pk_status == 2" class="margin-top-sm" src="img/status3.png" alt="">
                <!-- 管理员结束 -->
                <img v-if="item.pk_status == 5" class="margin-top-sm" src="img/status4.png" alt="">
                <!-- 平局 -->
                <img v-if="item.pk_status == 3" class="margin-top-sm" src="img/status5.png" alt="">
            </div>
          </div>

        </van-cell>
      </van-list>

    </div>

  </div>
</template>

<script>
// 移动端access_token
import "@/assets/css/rank.css"
import "@/assets/css/pk.css"
import {myPkRecord} from "@/api/pkCharm";

export default {
  name: 'pkRecord',
  data () {
    return {
      page:1,
      rows:10,
      pkList:[],
      loading: false,
      finished: false,
    }
  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    // this.fetchData()
  },
  methods: {
    fetchData() {
      const Params = {
        page:this.page,
        rows:this.rows
      }
      myPkRecord(Params).then(response => {
        this.pkList = this.pkList.concat(response.data)
        this.page = this.page+1
        // 加载状态结束
        this.loading = false
        // 数据全部加载完成
        if (response.data.length < 10) {
          this.finished = true;
        }
      },
      error => {
        this.$toast(error)
      }).catch((error) => {
        this.$toast(error)
      })
    },
    goNext(id){
      let access_token = this.$route.query.access_token
      this.$router.push({path:'/pk/recordDetail',query: {access_token:access_token,pk_id:id}})
    },
    closePage(){
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
      // 处理返回数据
      })
    },
    checkUser(id){
      let param = id.toString()
      this.$bridge.callhandler('checkUser', param, (data) => {
      // 处理返回数据
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.van-cell{
  line-height: normal;
}
</style>
